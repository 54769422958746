import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) =>
            instance.loginRedirect(loginRequest).catch(e => {
                console.log("Error!", e);
            });


    return (
        <Button onClick={() => handleLogin()}>Sign in</Button>
    )
}