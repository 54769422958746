import { gxiApiConfig } from "./gxiApiConfig";
import { loginRequest } from "./authConfig";
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";

const getUri = async (endpoint, options) => {
    try {
        console.log(gxiApiConfig)
        const response = await fetch(gxiApiConfig.baseAddress + endpoint, options);
        return await response.json();
    } catch (error) {
        return console.log(error);
    }
}

const getAuthorized = async (msal, uri) => {
    const { instance, accounts } = msal
    try {
        const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
            scopes: ['api://91a2350a-b3a5-4e3f-9cf9-e5e94059b44f/GXI.Read']
        });
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };
        return await getUri(uri, options);
    } catch (err) {
        console.log(err);
        if (err instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({ scopes: loginRequest.scopes });
        }
    }
}


export function loadGroupExercises(msal, id, instructor) {
    return getAuthorized(msal, `/sats/group-exercises?instructorName=${encodeURI(instructor)}&instructorId=${id}`);
}

export function getMemberImage(msal, memberId) {
    return getAuthorized(msal, `/sats/members/${memberId}/picture`);
}

export function getGroupExercise(msal, bookingId) {
    return getAuthorized(msal, `/sats/group-exercises/${bookingId}`);
}