import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { PageLayout } from "./components/PageLayout";
import useLocalStorageState from 'use-local-storage-state'
import Spinner from "react-bootstrap/Spinner";
import { loadGroupExercises, getMemberImage, getGroupExercise } from "./gxiApi"
import "./styles/App.css";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const GroupExerciseContent = () => {
    const [exerpInstructor, setExerpInstructor] = useLocalStorageState('exerpInstructor', {
        defaultValue: {id: "", name: ""}
    })
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [groupExercisesData, setGroupExercisesData] = useState(null);
    const [selectedGroupExercise, setGroupExercise] = useState(null);

    useEffect(()=> {
        loadGroupExercises({ instance, accounts }, exerpInstructor.id, exerpInstructor.name)
            .then((response) => setGroupExercisesData(response));
    },[]);

    return (
        <>
            {
                selectedGroupExercise ?
                    <GroupExercise groupExercise={selectedGroupExercise} setGroupExercise={setGroupExercise} />
                    : 
                    (groupExercisesData ? 
                        <GroupExercises groupExerciseData={groupExercisesData} setGroupExercise={setGroupExercise} />
                        :
                        <>
                        <Spinner animation="grow" role="status">
                            <span className="invisible">Loading group exercises...</span>
                        </Spinner>
                        <p className="font-weight-italic">Loading group exercises</p>
                        </>
                    )
            }
        </>
    );
};

const GroupExercises = (props) => {
    return (
        <>
            <h2>Your classes</h2>
            
            <ol className="list-group">
                {props.groupExerciseData.groupExercises.map((groupExercise, index) => (
                        <li key={groupExercise.id} className="list-group-item">
                            <button className="btn-primary" onClick={()=>props.setGroupExercise(groupExercise)}>
                                {groupExercise.displayStartDateTime}, {groupExercise.name}
                            </button>
                        </li>
                ))}
            </ol >
        </>
    )
}

const GroupExercise = ({setGroupExercise, setGroupExercisesData, groupExercise}) => {
    const { instance, accounts } = useMsal();
    const waitingList = groupExercise.participants.filter(p=>p.status === "OVERBOOKED_WAITINGLIST")
    const booked = groupExercise.participants.filter(p=>p.status === "BOOKED")
    const showedUp = groupExercise.participants.filter(p=>p.status === "PARTICIPATION")

    const refreshGroupExercise = async () => {
        await getGroupExercise({ instance, accounts }, groupExercise.id)
            .then((response)=>
                setGroupExercise(response.groupExercise));
    }

    return (
        <>
        <button className="btn-secondary" onClick={()=>setGroupExercise(null)}>⬅️ Back to list</button>
        <h3>{groupExercise.name}<br />
            <small className="text-muted">{groupExercise.displayStartDateTime}</small>
        </h3>
        
        <button className="btn-primary" onClick={refreshGroupExercise}>Refresh list</button>

        {showedUp.length > 0 && 
         (<><h4>Showed up <small className="text-muted">({showedUp.length}/{booked.length + showedUp.length})</small></h4>
            <Participants participants={showedUp} /></>
         )}

        <h4>Booked <small className="text-muted">({booked.length})</small></h4>
        {
            booked.length > 0
                ? <Participants participants={booked} />
                : <p><em>None yet</em></p>
        }

        <h4>Waiting list <small className="text-muted">({waitingList.length})</small></h4>
        {
            waitingList.length > 0
                ? <Participants participants={waitingList} />
                : <p><em>None yet</em></p>
        }
        </>
    )
}

const Participants  = ({participants}) => {
    return (
        <>
    {participants.map((participant)=>(
        <div className="media mt-3" key={participant.id}>
            <ParticipantImage id={participant.id} />
            <div className="media-body">
                <h5 className="mt-0">{participant.name}</h5>
                <p>Most recent class with you: {
                    participant.groupExerciseHistory.mostRecentGroupExerciseWithInstructor
                    ? participant.groupExerciseHistory.mostRecentGroupExerciseWithInstructor.displayStartDateTime
                    : "-"
                }<br />

                Most recent class of this type: {
                    participant.groupExerciseHistory.mostRecentGroupExerciseOfSameActivity
                    ? participant.groupExerciseHistory.mostRecentGroupExerciseOfSameActivity.displayStartDateTime
                    : <strong>First timer</strong>
                }</p>

                Most recent group exercises: {
                    participant.groupExerciseHistory.mostRecentGroupExercises.length == 0
                    ? <strong>First timer</strong>
                    : participant
                        .groupExerciseHistory
                        .mostRecentGroupExercises.map(gx=>(gx.activity.name + " (" + gx.displayStartDateTime + "), "))
                }
            </div>
        </div>

    ))}
    </>)
};

const ParticipantImage = (({id}) => {
    const { instance, accounts } = useMsal();
    const [image, setImage] = useState(null);
    

    useEffect(()=> {
        setImage("/images/anonymous-avatar-sm.jpg")
        getMemberImage({ instance, accounts }, id)
            .then((image)=>{
                if(image.data)
                    setImage(`data:${image.type};base64,${image.data}`)
            });
        }, []);
    return (<img height="200" width="200" src={image} className="mr-3" alt="" />)
});

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {    
    const [exerpInstructor, setExerpInstructor] = useLocalStorageState('exerpInstructor', {
        defaultValue: {id: "", name: ""}
    })
    return (
        <div className="App">
            <AuthenticatedTemplate>
                {
                    exerpInstructor
                        && exerpInstructor.id
                        && exerpInstructor.name 
                    ? <GroupExerciseContent />
                    : (<p>Set your instructor id</p>)
                }
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see your group exercises.</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
