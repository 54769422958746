import React from "react";
import {createRoot} from "react-dom/client";
import "./styles/bootstrap-custom.scss";
import "./styles/index.css";
import App from "./App.jsx";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
 const container = document.getElementById('root');
 const root = createRoot(container);
 root.render(
     <React.StrictMode>
         <MsalProvider instance={msalInstance}>
             <App />
         </MsalProvider>
     </React.StrictMode>);